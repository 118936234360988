import { LOCALE } from '@b2b-frontend/constants';
import { NextRequest } from 'next/server';

export const getPublicSiteURL = (host?: string): string | null => {
	if (host) {
		console.log(`getPublicSiteURL: host argument is no longer required.`);
	}

	return process.env.NEXT_PUBLIC_PUBLIC_SITE_URL || '';
};

export const getAdminSiteURL = (host: string): string => {
	if (host) {
		console.log(`getAdminSiteURL: host argument is no longer required.`);
	}

	return process.env.NEXT_PUBLIC_ADMIN_SITE_URL || '';
};

export const getApiURL = (hostname?: string): string | null => {
	return process.env.API_URL || process.env.NEXT_PUBLIC_API_URL || null;
};

export const getBucketName = (): string => {
	return process.env.S3_BUCKET_NAME || '';
};
