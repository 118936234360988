import React from 'react';
import { IPageHeading } from '@b2b-frontend/types';
import Heading from '../Heading';

interface IPageHeadingProps extends React.PropsWithChildren {
	guid?: string;
	content?: IPageHeading['content'];
}

const PageHeading: React.FC<IPageHeadingProps> = ({ children, content }) => {
	return (
		<div className="relative mt-10 md:mt-20 mb-16">
			<Heading as={'h2'} className="mb-2.5" size="text-base md:text-lg">
				{content ? content.text : children}
			</Heading>
			<div className="absolute b-0 w-[145px] md:w-[260px] transform -translate-x-6 md:-translate-x-1/2 border-brand border-t-2 inline-block" />
		</div>
	);
};

export default PageHeading;
